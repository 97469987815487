import { createFeatureSelector, createSelector } from '@ngrx/store';
import orderBy from 'lodash-es/orderBy';
import {
  TicketStatuses,
  TicketStatusesPriorityMap
} from '@app/common/tickets';
import {
  TicketsBaseState,
  TicketsEntity,
  TicketTopicGroup
} from './tickets-base.model';
import { ModuleTokens } from './tickets-base.constant';
import { denormalizeEntity } from './tickets-base.utils';
import { denormalizeList } from './tickets-base.utils';

export const slice = createFeatureSelector<TicketsBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const listIds = createSelector(slice, (s) => s.list);

export const getById = (id: string) => createSelector(
  entities,
  (ents) => {
    if (!ents) { return undefined; }

    const ent = denormalizeEntity(
      id,
      ents[ModuleTokens.Name],
      ents[ModuleTokens.Messages]
    ) as TicketsEntity;

    if (ent) {
      return {
        ...ent,
        ticketMessageList: orderBy(ent.ticketMessageList, [ 'created' ], [ 'asc' ])
      };
    } else {
      return ent;
    }
  }
);

const openStatuses = [
  // TicketStatuses.Planned,
  TicketStatuses.Resolved,
  TicketStatuses.Accepted,
  TicketStatuses.InRealisation,
  TicketStatuses.WaitingForClient,
  TicketStatuses.WaitingForAuthorisation
];

export const openedShortlist = (num = 3) => createSelector(
  listIds,
  entities,
  (ids, ents) => {

    const data = denormalizeList(
      ids,
      ents[ModuleTokens.Name],
      ents[ModuleTokens.Messages]
    ) as TicketsEntity[];

    const orderedData = orderBy(
      data,
      [ (t) => TicketStatusesPriorityMap[t.clientZoneStatus], 'lastUpdate' ],
      [ 'desc', 'desc' ]
    );

    return orderedData
      .filter((item) => openStatuses.includes(item.clientZoneStatus))
      .slice(0, num);
});

export const ticketTopicList = createSelector(
  slice,
  (s) => s.ticketTopics
);

export const ticketTopicById = (id: string) => createSelector(
  ticketTopicList,
  (list) => list.find((topic) => topic.id === id)
);

export const ticketTopicListGrouped = createSelector(
  slice,
  (s) => {
    const groupOrder = ['BILLING', 'SALES', 'TECHNICAL'];
    const groupMap = new Map<string, TicketTopicGroup>();

    s.ticketTopics.forEach((item) => {
      if (!groupMap.has(item.group)) {
        groupMap.set(item.group, { key: item.group, items: [] });
      }
      groupMap.get(item.group)!.items.push(item);
    });

    const grouped = Array.from(groupMap.values()).map((group) => ({
      ...group,
      items: orderBy(group.items, ['order'])
    }));

    return orderBy(grouped, [(group) => {
      const index = groupOrder.indexOf(group.key);
      return index !== -1 ? index : Infinity;
    }]);
  }
);
