import { StandardService } from '@app/common/settings';
import { ModuleTokens } from './servers-base.constant';

export class ServersBaseState {
  entities: ServersEntityEntities;
  list: string[];
  ipV6Range: IpV6Range[];
  ipV4Range: IpV4Range[];
  freeIpV4List: IpV4Address[];
  usedIpV6List: IpV6Address[];
  ipRangesLoaded: boolean;

  graphs: {
    [id: string]: {
      [type: string]: ServerGraphFormatted[][];
    };
  };

  constructor() {
    this.entities = {
      [ModuleTokens.Name]: { }
    };
    this.list = [];
    this.graphs = { };
    this.ipRangesLoaded = false;
  }
}

export class ServersEntityEntities {
  [ModuleTokens.Name]: { [id: string]: ServerEntityNormalized; };
}

export class ServersEntityNormalizedData {
  entities: ServersEntityEntities;
  result: string[];
}

export class ServerEntityLight {
  id: string;
  clientId: string;
  hostName: string;
  deviceNumber: string;
  note: string;
  standardService: StandardService;
  serverStats: ServerStatsEntity;
  location: string;
  rackNumber: string;
  formFactor: string;
}

export class ServerEntityBase extends ServerEntityLight {
  created: string;
  lastUpdate: string;
  muninUrl: string;
  grafanaUrl: string;
  kvmPassword: string;
  kvmType: string;
  kvmUsername: string;
  serverIpV4List: ServerIpV4[];
  serverIpV6List: ServerIpV6[];
}

export class ServerEntityNormalized extends ServerEntityBase {

}

export class ServerEntity extends ServerEntityBase {

}

export class ServerStatsEntity {
  serverId: string;
  hostName: string;
  cpuUsage: number;
  maxDiskSpaceQuotaUsage: number;
  memoryUsage: number;
  serverGraphList: ServerGraph[];
}

export class ServerGraph {
  name: string;
  graphDisplayType: string;
  unit: string;
  graphType: string;
  series?: {
    name: string;
    dateTime: string[];
    median: number[];
    minValue: number[];
    maxValue: number[];
    value: number[];
  }[];
}

export class IpV4Address {
  ipV4: string;
  serverIpV4Id: string;
  ipV4RangeId?: string;
}

export class IpV6Address {
  ipV6: string;
  serverIpV6Id: string;
}

export class ServerIpV4 {
  id: string;
  serverId: string;
  ipV4RangeId: string;
  ipV4: string;
  ptr: string;
  restrictedIp: boolean;
}

export class ServerIpV6 {
  id: string;
  serverId: string;
  ipV6RangeId: string;
  ipV6: string;
  ptr: string;
}

export class ServerGraphFormatted {
  min: number;
  max: number;
  value: number;
  label: string;
  group: string;
}

export class IpRangeApiResponse {
  ipV4RangeList: IpV4Range[];
  ipV6RangeList: IpV6Range[];
  clientFreeIpV4AddressList: IpV4Address[];
  clientUsedIpV6AddressList: IpV6Address[];
}

export class IpV4Range {
  id: string;
  clientId: string;
  ipV4: ServerIpV4;
  mask: number;
  available: boolean;
}

export class IpV6Range {
  id: string;
  clientId: string;
  ipV6Start: ServerIpV6;
  ipV6End: string;
  mask: number;
  available: boolean;
}

export class IpV6RangeExpanded extends IpV6Range {
  _ipV6StartExpanded: string;
  _ipV6EndExpanded: string;
}

export class IpV6RangeForForm extends IpV6RangeExpanded {
  _ipV6StartExpandedStripped: string;
  _ipV6StartExpandedDynamicPartMask: (string | RegExp)[];
}

export enum KvmStatuses {
  on = 'ON',
  off = 'OFF',
  unknown = 'UNKNOWN'
}

export class KvmStatus {
  kvmStatus: KvmStatuses;
  kvmConsoleTypes: string[];
  kvmLog: KvmLogMessage[];
}

export class KvmLogMessage {
  eventId: string;
  datetime: string;
  sensorType: string;
  event: string;
  status: string;
}

export enum KvmCommands {
  Reboot = 'REBOOT',
  On = 'ON',
  Off = 'OFF',
  SoftOff = 'SOFT_OFF',
  Cycle = 'CYCLE'
}
