import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServersBaseState, IpV6RangeForForm, IpV6RangeExpanded, ServerEntity } from './servers-base.model';
import { ModuleTokens } from './servers-base.constant';
import { denormalizeEntity, expandIPv6Address, denormalizeList } from './servers-base.utils';

export const slice = createFeatureSelector<ServersBaseState>(ModuleTokens.Name);

export const entities = createSelector(
  slice,
  (s) => s.entities
);

export const listIds = createSelector(
  slice,
  (s) => s.list
);

export const listEntities = createSelector(
  listIds,
  entities,
  (ids, ents) => {

    if (!ents) { return undefined; }

    return denormalizeList(
      ids,
      ents[ModuleTokens.Name]
    ) as ServerEntity[];
  }
);

export const getById = (id: string) => createSelector(
  entities,
  (ents) => {
    if (!ents) { return undefined; }
    return denormalizeEntity(
      id,
      ents[ModuleTokens.Name]
    );
  }
);

export const getGraphByServerId = (id: string) => createSelector(
  slice,
  (s) => s.graphs[id]
);

export const ipV4Range = createSelector(slice, (s) => s.ipV4Range);

export const ipV6Range = createSelector(slice, (s) => s.ipV6Range);

export const ipV6RangesExpanded = createSelector(
  ipV6Range,
  (ips): IpV6RangeExpanded[] => ips
  ? ips.map((item) => {
      return {
        ...item,
        _ipV6StartExpanded: expandIPv6Address(item.ipV6Start),
        _ipV6EndExpanded: expandIPv6Address(item.ipV6End)
      };
    })
  : []
);

export const ipV6RangesForForm = createSelector(
  ipV6RangesExpanded,
  (ips): IpV6RangeForForm[] => ips
    ? ips.map((itm) => {
        const blockSize = 4;
        const blocks = itm.mask / 16;
        const startLen = blocks * blockSize + Math.floor(blocks);
        const startLenFloored = Math.floor(startLen);

        const breakpointMin = parseInt(itm._ipV6StartExpanded.charAt(startLenFloored), 16);
        const breakpointMax = parseInt(itm._ipV6EndExpanded.charAt(startLenFloored), 16);
        const breakpointRegex = new RegExp("[" + new Array(breakpointMax - breakpointMin + 1).fill(0).map((_, index) => (index + breakpointMin).toString(16)).join('') + "]");

        return {
          ...itm,
          _ipV6StartExpandedStripped: itm._ipV6StartExpanded.slice(0, startLenFloored),
          _ipV6StartExpandedDynamicPartMask: [
            breakpointRegex,
            ...itm._ipV6StartExpanded.slice(startLenFloored + 1).split('').map((char) => char === ':' ? char : /[0-9a-f]/)
          ]
        };
      })
    : []
);

export const freeIpV4List = createSelector(slice, (s) => s.freeIpV4List);

export const usedIpV6List = createSelector(slice, (s) => s.usedIpV6List);
