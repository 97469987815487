import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { State } from '@app/models';
import { TicketsEntity } from '@app/base/tickets-base';
import { SnackService } from '@app/common/snack';
import { listEntities, filters } from './tickets-list.selector';
import { ResetFilter } from './tickets-list.action';

@Component({
  selector: 'vshcz-tickets-list',
  templateUrl: './tickets-list.container.html',
  styleUrls: [ './tickets-list.container.scss' ]
})
export class TicketsListContainer extends BaseClass {
  // # Event Streams
  onResetFilter$ = new Subject<void>();

  // # Data
  // -- angular
  @Input()
  height: number;

  @Input()
  selectedId: string;

  @Input()
  showActions = true;

  @Output()
  selected = new EventEmitter<TicketsEntity>();

  // -- async
  // TODO: move to base
  list$ = this._store.pipe(select(listEntities));
  activeFilters$ = this._store.pipe(select(filters));

  // # Action Streams
  private _resetFilterAction$ = this.onResetFilter$.pipe(
    map(() => new ResetFilter())
  );

  constructor(
    private _store: Store<State>,
    private _clipboardService: ClipboardService,
    private _snack: SnackService
  ) {
    super();

    // # Store Dispatcher
    this._resetFilterAction$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }

  copyLinkToClipboard(data: TicketsEntity) {
    const url = `${window.location.href};ticket=${data.id}`;

    this._clipboardService.copyFromContent(url);

    this._snack
      .translatedSuccess$('common.copied', 'common.close')
      .subscribe();
  }

  trackBy(_, item: TicketsEntity) {
    return item?.id;
  }

}
