import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RemoveError } from '@zerops/fe/ngrx';
import {
  mergeMap,
  map,
  catchError,
  filter,
  switchMap
} from 'rxjs/operators';
import { ErrorTranslationService } from '@app/services';
import { SnackService } from '@app/common/snack';
import { State } from '@app/models';
import { ServersBaseApi } from './servers-base.api';
import {
  ActionTypes,
  AddIpV4Request,
  AddIpV4LocalSuccess,
  AddIpV4Fail,
  DeleteIpRequest,
  DeleteIpFail,
  DeleteIpLocalSuccess,
  AddIpV6Request,
  AddIpV6LocalSuccess,
  AddIpV6Fail,
  SavePtrRequest,
  SavePtrLocalSuccess,
  SavePtrFail
} from './servers-base-ip.action';
import { environment } from 'environments/environment';
import { toBoolean } from 'utils';

@Injectable()
export class ServersIpBaseEffect {

  private _networkEnabled = toBoolean(environment.enableNetworkService);

  // add ipv4
  private _onAddIpV4Request$ = createEffect(() => this._actions$.pipe(
    ofType<AddIpV4Request>(ActionTypes.AddIpV4Request),
    filter((_) => !this._networkEnabled),
    mergeMap(({ payload: { serverId, ipV4Address, ptr } }) => this._api
      .addIpV4$(serverId, ipV4Address.serverIpV4Id)
      .pipe(
        map((response) => new AddIpV4LocalSuccess(
          response,
          serverId,
          ptr,
          ipV4Address.serverIpV4Id,
          ipV4Address.ipV4
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new AddIpV4Fail(data, serverId)))
        )
      )
    )
  ));

  private _onNetworkAddIpV4Request$ = createEffect(() => this._actions$.pipe(
    ofType<AddIpV4Request>(ActionTypes.AddIpV4Request),
    filter((_) => this._networkEnabled),
    mergeMap(({ payload: { serverId, ipV4Address, ptr } }) => this._api
      .networkAddIpV4$(serverId, ipV4Address.ipV4, ipV4Address.ipV4RangeId, ptr)
      .pipe(
        map((response) => new AddIpV4LocalSuccess(
          response,
          serverId,
          ipV4Address.ipV4
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new AddIpV4Fail(data, serverId)))
        )
      )
    )
  ));

  // add ipv6
  private _onAddIpV6Request$ = createEffect(() => this._actions$.pipe(
    ofType<AddIpV6Request>(ActionTypes.AddIpV6Request),
    filter((_) => !this._networkEnabled),
    mergeMap(({ payload }) => this._api
      .addIpV6$(payload.serverId, payload.ip)
      .pipe(
        map((response) => new AddIpV6LocalSuccess(
          response,
          payload.serverId,
          payload.ptr,
          payload.ip
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new AddIpV6Fail(data, payload.serverId)))
        )
      )
    )
  ));

  private _onNetworkAddIpV6Request$ = createEffect(() => this._actions$.pipe(
    ofType<AddIpV6Request>(ActionTypes.AddIpV6Request),
    filter((_) => this._networkEnabled),
    mergeMap(({ payload }) => this._api
      .networkAddIpV6$(payload.serverId, payload.ip, payload.ipRangeId, payload.ptr)
      .pipe(
        map((response) => new AddIpV6LocalSuccess(
          response,
          payload.serverId,
          payload.ip
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new AddIpV6Fail(data, payload.serverId)))
        )
      )
    )
  ));

  private _onAddIpV6V4LocalSuccess$ = createEffect(() => this._actions$.pipe(
    ofType<AddIpV6LocalSuccess | AddIpV4LocalSuccess>(
      ActionTypes.AddIpV6LocalSuccess,
      ActionTypes.AddIpV4LocalSuccess
    ),
    filter(({ meta }) => !!(meta && meta.ptr && meta.id)),
    map(({ meta }) => new SavePtrRequest({
      ptr: meta.ptr,
      serverId: meta.serverId,
      id: meta.id,
      ip: meta.ip
    }))
  ));

  // remove ip
  private _onDeleteIpRequest$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteIpRequest>(ActionTypes.DeleteIpRequest),
    mergeMap(({ payload }) => this._api
      .removeIp$(payload.serverId, payload.id)
      .pipe(
        map((response) => new DeleteIpLocalSuccess(
          response,
          payload.id,
          payload.ip,
          payload.type
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new DeleteIpFail(data, payload.id)))
        )
      )
    )
  ));

  private _onSavePtrRequest$ = createEffect(() => this._actions$.pipe(
    ofType<SavePtrRequest>(ActionTypes.SavePtrRequest),
    switchMap(({ payload }) => this._api
      .savePtr$(payload.serverId, payload.id, payload.ptr)
      .pipe(
        map((response) => new SavePtrLocalSuccess(
          response,
          payload.id,
          payload.ip
        )),
        catchError((err) => this._errorTranslation
          .get$(err)
          .pipe(map((data) => new SavePtrFail(data, payload.id)))
        )
      )
    )
  ));

  // snacks
  private _onAddIpV4LocalSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<AddIpV4LocalSuccess>(ActionTypes.AddIpV4LocalSuccess),
    switchMap(({ meta }) => this._snack.translatedSuccess$(
      'serversBase.ip4AddSuccessSnack',
      'common.close',
      {
        ip: meta.ip
      }
    ))
  ), { dispatch: false });

  private _onAddIpV6LocalSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<AddIpV6LocalSuccess>(ActionTypes.AddIpV6LocalSuccess),
    switchMap(({ meta }) => this._snack.translatedSuccess$(
      'serversBase.ip6AddSuccessSnack',
      'common.close',
      {
        ip: meta.ip
      }
    ))
  ), { dispatch: false });

  private _onDeleteIpLocalSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteIpLocalSuccess>(ActionTypes.DeleteIpLocalSuccess),
    switchMap(({ meta }) => this._snack.translatedSuccess$(
      'serversBase.ipDeleteSuccessSnack',
      'common.close',
      {
        ip: meta.ip
      }
    ))
  ), { dispatch: false });

  private _onUpdatePtrLocalSuccessSnack$ = createEffect(() => this._actions$.pipe(
    ofType<SavePtrLocalSuccess>(ActionTypes.SavePtrLocalSuccess),
    switchMap(({ meta }) => this._snack.translatedSuccess$(
      'serversBase.ptrSaveSuccessSnack',
      'common.close',
      {
        ip: meta.ip
      }
    ))
  ), { dispatch: false });

  private _onDeleteIpFailSnack$ = createEffect(() => this._actions$.pipe(
    ofType<DeleteIpFail>(ActionTypes.DeleteIpFail),
    switchMap(({ meta }) => this._snack.translatedFail$(
      meta.message,
      'common.close'
    )),
    map(() => new RemoveError(ActionTypes.DeleteIpFail))
  ));

  constructor(
    private _actions$: Actions,
    private _api: ServersBaseApi,
    private _store: Store<State>,
    private _snack: SnackService,
    private _errorTranslation: ErrorTranslationService
  ) { }
}
